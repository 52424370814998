export default class SocketChannels {
  static calibration(cycle_id, calibrator_id) {
    return {
      channel: 'CalibrationChannel',
      cycle_id,
      calibrator_id
    };
  }
  static backgroundJobProgress(params) {
    return {
      channel: 'BackgroundJobProgressChannel',
      ...params
    };
  }
}
